/**
  primary: 'rgba(30, 39, 70, 1)', // #1E2746
  secondary: 'rgba(101, 194, 196, 1)', // #65C2C4
  brand: 'rgba(30, 39, 70, 1)',
 */

/**
 * BUTTON STYLE
 */
.ant-btn-primary {
    background-color: #65C2C4 !important;
    border-color: #65C2C4
}

/**
 * PAGINATION STYLE
 */
.ant-pagination-item-active {
    border-color: #65C2C4 !important;
}

li.ant-pagination-item-active a {
    color: #65C2C4 !important;
}

/**
 * MENU STYLE
 */
.ant-menu-item-selected {
    background-color: #65C2C4 !important;
}

.ant-menu-item-selected span {
    color: white !important;
}

.ant-menu-item-active {
    color: #65C2C4 !important;
}

